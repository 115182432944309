import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Docker DesktopからOrbStackへ移行してみた",
  "date": "2025-03-25T06:11:00.000Z",
  "slug": "entry/2025/03/25/151147",
  "tags": [],
  "hero": "./thumbnail.png",
  "heroAlt": "Docker DesktopからOrbStackへ移行してみた"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`こんにちは。人材プラットフォーム本部 第一開発グループの坂井(`}<a parentName="p" {...{
        "href": "https://x.com/Hiroshi_mars"
      }}>{`@Hiroshi_mars`}</a>{`)です。
2024 年 12 月に主にバックエンドのエンジニアとしてメドレーに入社しました。
入社して日が浅いですが、この記事を通じて少しでもためになったりメドレーについて知っていただけたら嬉しいです。`}</p>
    <p>{`今回、私は、Docker Desktop for Mac から OrbStack へ移行するプロジェクトを担当しました。以降、Docker Desktop は Docker Desktop for Mac を指します。`}</p>
    <ul>
      <li parentName="ul">{`対象プロダクト: `}<a parentName="li" {...{
          "href": "https://job-medley.com/"
        }}>{`ジョブメドレーのサイトページ`}</a>{`や社内オペレーターが利用するサイト、施設の方々が利用するサイトなど。`}</li>
      <li parentName="ul">{`期間: 2~3 週間`}</li>
    </ul>
    <h1>{`OrbStack とは`}</h1>
    <p><a parentName="p" {...{
        "href": "https://orbstack.dev/"
      }}>{`公式`}</a></p>
    <p>{`OrbStack とは`}</p>
    <blockquote>
      <p parentName="blockquote">{`OrbStack is the fast, light, and easy way to run Docker containers and Linux. Develop at lightspeed with our Docker Desktop alternative. (公式サイトから引用)`}</p>
    </blockquote>
    <p>{`OrbStack は、Docker コンテナおよび Linux を高速かつ軽量に、容易に実行するためのツールです。
と書かれています。
Docker Desktop の代替として注目されています。`}</p>
    <h2>{`OrbStack の特徴`}</h2>
    <ol>
      <li parentName="ol">{`Docker Desktop より高速で軽量`}</li>
      <li parentName="ol">{`既存の Docker コマンドや docker-compose 系のコマンドも利用可能`}</li>
      <li parentName="ol">{`Docker Desktop からの移行が容易`}</li>
    </ol>
    <p>{`CPU やメモリの使用量が抑えられ、既存の Docker コマンドとの互換性も高いため、移行や運用の負担が少ない点が大きなメリットです。`}</p>
    <h1>{`OrbStack へ移行した理由`}</h1>
    <p>{`大きく二点あります。`}</p>
    <h2>{`OrbStack へ移行した理由 その 1`}</h2>
    <p><strong parentName="p">{`【OrbStack と Docker Desktop の商用利用の料金差】`}</strong></p>
    <ul>
      <li parentName="ul">{`OrbStack の料金は`}<a parentName="li" {...{
          "href": "https://orbstack.dev/pricing"
        }}>{`こちら`}</a></li>
      <li parentName="ul">{`Docker Desktop の料金は`}<a parentName="li" {...{
          "href": "https://www.docker.com/ja-jp/pricing/"
        }}>{`こちら`}</a></li>
    </ul>
    <p>{`今まで Docker Desktop の`}<strong parentName="p">{`Docker Team`}</strong>{`のプランで商用利用していました。しかし、ありがたいことに開発メンバーが増加し、管理できるアカウント数の上限を超えてしまいそうになりました。`}</p>
    <p>{`Docker Desktop のプランを`}<strong parentName="p">{`Docker Team`}</strong>{`から`}<strong parentName="p">{`Docker Business`}</strong>{`に上げると 1 ユーザーあたりの月額利用料が 15 ドルから 24 ドルに増加します。
そのため、「プランのランクを一つ上に上げる」or「別のツールに移行する」という選択がありました。`}</p>
    <p>{`「プランのランクを一つ上に上げる」のも可能ではありましたが、`}<a parentName="p" {...{
        "href": "#orbstack-%E3%81%B8%E7%A7%BB%E8%A1%8C%E3%81%97%E3%81%9F%E7%90%86%E7%94%B1-%E3%81%9D%E3%81%AE-2"
      }}>{`OrbStack へ移行した理由 その 2`}</a>{`に記載されている通りどうやら移行難易度や工数コストが低そうということもあって、「別のツールに移行する」選択をとりました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "972px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0c87b89685e62a7b0041b6e3588769a2/7ae9c/cost_comparison.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABqElEQVQoz5WSPUtjQRSG8ydk0WotrCz8BdqYKtVWgqAWIqzVIu52Fq4g7oKN2GgpCEokrOiykbBZYxASFdQQo3D91mgkxosmITdfd+aRjPm4SFLsC4eZOWfOO+e8Z2wABRPiiSTPqSxGXpLOFCkIFKSUSGpQZ4tZfSXYQKJHd/GszhMKrnN17Oc05EWP7gB5SvcaEdQjt6klOgGhJjj6CFobRJrhcghhCkwhEGV7S8Rar8WEitmUWxiQdPJ0+h3/xiDXkZ/kjBiNcKLd4vUdEj654/Y+jXae4CVVKLdseTVTgMDRAyveY2aXPMwsruH6G2Blw8/6ZpC0kSspDmdfyO51kTtwkNl3kNrtwrwcr2ioREGKoiq7Jr7gJpZgJ6zR+3WK3m8/mHO6mV5w43KNsffrA1f/WsiH2+GiG9LbqnE1FKvYb5pJZRV0fBqms29U7fWXFAdanM39O1Z9ERJJWVUTKcoVvvsWFbLYo47j8xit9gFa7f30jEySMbLvM5CyqMhqLdchVYTxJ5b/+PjtCyoNne4t9OekihVNszr5ao6U9QmtpP8bewVa/uNHu6+znAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "cost comparison",
            "title": "cost comparison",
            "src": "/static/0c87b89685e62a7b0041b6e3588769a2/7ae9c/cost_comparison.png",
            "srcSet": ["/static/0c87b89685e62a7b0041b6e3588769a2/5a46d/cost_comparison.png 300w", "/static/0c87b89685e62a7b0041b6e3588769a2/0a47e/cost_comparison.png 600w", "/static/0c87b89685e62a7b0041b6e3588769a2/7ae9c/cost_comparison.png 972w"],
            "sizes": "(max-width: 972px) 100vw, 972px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`この判断はメドレーの行動原則である(`}<a parentName="p" {...{
        "href": "https://www.medley.jp/team/culture.html"
      }}>{`Our Essentials`}</a>{`)の中の`}<strong parentName="p">{`『日々の倹約と大胆な投資の両立』`}</strong>{`に適っています。
無駄なコストを落とせるのなら落とし、落とした分だけ別の大胆な行動に利用する。そういった行動原則に基づいた動きでもあったかと思います。
逆に、移行による工数コストがかなり高かった場合、Docker Desktop のプランを上げる方針になっていた可能性もあります。`}</p>
    <h2>{`OrbStack へ移行した理由 その 2`}</h2>
    <p><strong parentName="p">{`【OrbStack へ移行が容易】`}</strong></p>
    <p>{`ブログなどの各種情報源において、OrbStack への移行手順は容易であり移行にかかる工数が低いことが報告されています。`}</p>
    <blockquote>
      <p parentName="blockquote">{`他ブログの一例: `}<a parentName="p" {...{
          "href": "https://qiita.com/shoki-y/items/4b8e48a525062a8ec9ad"
        }}>{`https://qiita.com/shoki-y/items/4b8e48a525062a8ec9ad`}</a></p>
    </blockquote>
    <p>{`また、社内の別プロダクトで既に移行した実績がありました。 社内の別プロダクトの技術スタックは、今回の移行対象と近しいものでした。`}</p>
    <p>{`そのため、社内の別プロダクトの移行を行なった方にヒアリングした際も移行コストが小さいことがわかりました。`}</p>
    <p>{`移行作業に伴う環境差異やそれに伴う工数負担は大きな課題となりやすいですが、OrbStack ではそれらの負担が少ないことが社内、社外の知見調査によって見込まれたため、移行を決断するに至りました。`}</p>
    <h1>{`OrbStack への移行手順`}</h1>
    <h2>{`1. OrbStack をインストール`}</h2>
    <p>{`インストール方法は 2 種類あります。`}</p>
    <ol>
      <li parentName="ol">{`Homebrew を使ったインストール`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`brew `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` orbstack`}</code></pre></div>
    <p>{`2.`}<a parentName="p" {...{
        "href": "https://orbstack.dev/download"
      }}>{`公式サイト`}</a>{`から dmg 形式のファイルをダウンロードする`}</p>
    <h2>{`2. OrbStack の起動`}</h2>
    <p>{`インストール後、Finder のアプリケーションから OrbStack のアイコンを探し、クリックし起動。起動後、以下のような画面が出てきます。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1180px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1e12d7c7834ca91a6d7a1f0a275bddd1/c83ae/OrbStack_menu.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAADK0lEQVQ4yz2USXLrNhiEdRM/SiKJkbNGkqIGSrZsSfazHMeZVqlKKpVltjlBlrlDzpGjfSlAL2/RBWCBRqP//v9BPzGkiUSKGCUFSimUEmgVo3WMcquMSWSElbe9h4hQDv4s/CpFxEBoi4eUxMoS25JYakIxJLQBofqECj9Rju8oxneoMECHIxIRoeMQrSTWarQSxOGIgUwrhIMyCFsSpgvyWc3z25n+ZcP+vOHYr/h21/G26zju1pz7npnW2DjCGk2apVijiKMxA2VTpEm8QuEUmpw0L2m7Jc12TrOes2mXHJo5+3bh99u2JlcS5RRKgdHK2+UJE5tgjUV5QqcyRyiNFCFGO38iRDxGidAjjkcIM0QlI6Qce++1uNXAE5b5hCKr0EoTmZwwq4l05r3JU0tipH/dnZ3xQo2JioB4EiDNCBlJTGxRnjRikKUZaeKQYtMSk039mlhDniUUeYaVY1IjyNIEpSNsFaMngpERuB+mJkXGX6qsnbnGsGobNrsDq9MH211PUy9YzKfsNi2P11+5v/zEelVTlTlN13A9bvnzueOw7aibGvX/l7V1ynK6ZkF7/p7pb3+z2e1ZtUu6rma3XvDjH/9w/fkv2nrGdFLRbNb8/vrAv78cedp1LLoVWksiFxttLCZJWLUtT6czH9/9wP3DkbZZ0ixnXE6PfLx/w/v1jb7fUxQ5/XbN5fzE55cL/WFP27UoJYkiR+jKriSbruXlcub9euX5fPJfnk0rHh/uebu+cv38yr7vKfKUQ7/mcrrn+LDn9HSkW7XeP09otPShnE0quuWcw6phtZwzqQrKMme5nLOYz6lnNYtZTZYY2jpj0xUs54X3eTopUfILoSNzcKYmWlK5qGjpz7fAOrMjZKw9XP/m6ZAyD9BqeMuruuU1vilUXy+6pneK3d5dtFr6HlU+vBotFDKOMC46xg2G0A8J39cu9I4wy0sm09mN1E+P2xRxuasyQZEIUmUp1YRSVSTK+CLqzHWYZCoEjZRUUiDjMYO8qJjMFozHY4bB3VfIKGDbFFSpIB6GlKOGYrggHEbYJKOcZYThkFkQcAqGFMEnRsEd/wG3lPpqfatAZwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "OrbStack menu",
            "title": "OrbStack menu",
            "src": "/static/1e12d7c7834ca91a6d7a1f0a275bddd1/c83ae/OrbStack_menu.png",
            "srcSet": ["/static/1e12d7c7834ca91a6d7a1f0a275bddd1/5a46d/OrbStack_menu.png 300w", "/static/1e12d7c7834ca91a6d7a1f0a275bddd1/0a47e/OrbStack_menu.png 600w", "/static/1e12d7c7834ca91a6d7a1f0a275bddd1/c83ae/OrbStack_menu.png 1180w"],
            "sizes": "(max-width: 1180px) 100vw, 1180px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
Docker, Kubernetes, Linux どれを利用するのか選択します。 今回は、Docker Desktop から OrbStack に移行するので、Docker を選択します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/296f5bdfa8804d8da0e548d3a205713d/ec09f/OrbStack_top.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACA0lEQVQ4y32T224TMRCG8ypNYnvsPdu73kNLmqRJ4AJVoKZVCSCouECUQylSi7hAiAp4gt7zqD9abxIlacrFL1sz429+j3dbpSZ4sYCUHMYkmEzGqMoCaWqQZSny3KIsCxRF7vZ1zNoMge9BEoeSwqneB75C6zq/xu+9X7hWVyi5BRNtKGoK/icSfAlagOu1NUnHOOwdopQ5PEHO6aLr5oHN+La1lWiNwMToig6E6DbJFainyKmJi62u1oA27yPaG4IrHyTY8lAtzjrQSYQw8CB4dw22zXXjMLGIbAlSygFXu6Umgc0M8jxDHIVrbhaqG9VyZuoZ9nSGfqoRELsDNDqG0Q00icM1V3Vt/apNTYw4ChqHVRjiIDOIFC2B9Ss3h7iLEWdzGK3kmBtFkWcoC+tu44BKevB8DUnSPYqgDkh2wHgbjO2A8bnYDrhoQ8iOq7n3yow02mofjHxEIsIjeYKJmOJJ+gLT8jWOildOx9UZHkfP8FBMMZFP4ZO684k5h0Q+SKXgJGC4xUv/Cmf6K84f/MCn4Q0+9H/iY63hDd5W3/HGfMMJnSMQAUiy5QiWQKUEgjAEl10YYTGTXzBTlzilC5zyC9xmf/EnvsURe4+Z/Izn8hLH9O5+4IEdYTQaIdExfPKxSwNU1EdFA+zKAfo0xj6NUMkmXudL6kHR/K/aAP4DoKaX/f2qHFMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "OrbStack top",
            "title": "OrbStack top",
            "src": "/static/296f5bdfa8804d8da0e548d3a205713d/c1b63/OrbStack_top.png",
            "srcSet": ["/static/296f5bdfa8804d8da0e548d3a205713d/5a46d/OrbStack_top.png 300w", "/static/296f5bdfa8804d8da0e548d3a205713d/0a47e/OrbStack_top.png 600w", "/static/296f5bdfa8804d8da0e548d3a205713d/c1b63/OrbStack_top.png 1200w", "/static/296f5bdfa8804d8da0e548d3a205713d/d61c2/OrbStack_top.png 1800w", "/static/296f5bdfa8804d8da0e548d3a205713d/ec09f/OrbStack_top.png 1916w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
Docker を選択すると上記のようなトップ画面が出ます。`}</p>
    <h2>{`3. Docker Desktop から OrbStack へコンテナやイメージの情報を移行する`}</h2>
    <p>{`2 種類方法があります。`}</p>
    <ol>
      <li parentName="ol">{`トップ画面に表示されている"Migrate from Docker Desktop”をクリックする`}</li>
      <li parentName="ol">{`上部メニューから”File”->“Migrate Docker Data”をクリックする
Docker Desktop 上のコンテナ、ボリューム、イメージが OrbStack に移行されます`}</li>
    </ol>
    <p>{`＊2 のメニューについての画面
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1004px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c11e1a0b77e6c6bbbe159d202a410509/73b94/migrate_menu.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAABIElEQVQY0yXFyUoCAQCAYZ8uuvUKUUkkRBDhEkQzLkULRpfyUEfrYF1KcTKKMBGbxsrRWdWZcRkLDV/hj+rw8QUM/Z03pcrjQ5FmQ0aWy5TLJVT1FaerY9tNbEul37OpK1UsU6XnWXQ7Gp5rosgVXMfAcwyaqkJgOukxnXhMxy5to87xocjRvsBuIkZKjJIUosS3I6TEGDvxTRJClEr5nu9xn5HfZTho4w8dRr6LP2gT+BpofPZbf2rPEhtbCcLxNKuxFKFIkpXwrxSL60mWI3vMrwlcXefouSam3sA0WtiWhufoDDyTgGPW6OhVtI8K0s0lS+knQmc2wYxOMGOwcPL/3EGL2V2NGfGF0/MsxaJENlfgIpfnNp+nJBW4kwr8AHP0+zc9bDXwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "migrate menu",
            "title": "migrate menu",
            "src": "/static/c11e1a0b77e6c6bbbe159d202a410509/73b94/migrate_menu.png",
            "srcSet": ["/static/c11e1a0b77e6c6bbbe159d202a410509/5a46d/migrate_menu.png 300w", "/static/c11e1a0b77e6c6bbbe159d202a410509/0a47e/migrate_menu.png 600w", "/static/c11e1a0b77e6c6bbbe159d202a410509/73b94/migrate_menu.png 1004w"],
            "sizes": "(max-width: 1004px) 100vw, 1004px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`migrate した後、下記のようなポップアップが出ることがあります。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/61ec8e7b5ea6adce7959e5d258e66918/9685e/migrate_error_mosaic.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAABrUlEQVQ4y62UbW7TQBCGfQjEj8qxE3t3ZtdukqZJ/JHQ1EAhlSKggj9cgBNUvUJv0CtwN07xopk66QepVFr/eDS7M7PP7kpeB94x8swjyzwcE4iMwmyRJDHqZYPLqxucvF8jGUSaJ5sqzhrklCIng0xgi0BEo9EYw+EQ3jO4FVprEIZvsV7/xO/rP/j2/RcODt5ofrupSI5sirG9lR4+FI7gvYNjq6eYTMYo5lOUZYlPn79oLIsZptOJ1qUvZ4MjukWFZBHINb33rYy0mcmimM+wOnmH5aJGXc9RVQWa0xXqqtS64Mgia/EaCUGSJHhMmqbo9/uIoghRFCOO+4jjGL1eT6PUd/3tWHJkW6FM7rMvt7/exnZuzRPC/2XrMIbvhK8Ty/UlUjdCWUtEOD6edSMcDAZgdqjKRZdCRtm1sKqWHQsfn/Al3BcWRf2879AY88wrL/Y/PUGenTw1IQzD3TiKev/0PhA6l8O5DI4zjSzR51itGjTNRzTNB5ydrTUKkvc+B5EDkwcpDszygzlE4F2uki3SIJucn2+w2XxVLi5+7MaSl4XWulZ2h+T+Agk/DQ1ri6HdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "migrate error mosaic",
            "title": "migrate error mosaic",
            "src": "/static/61ec8e7b5ea6adce7959e5d258e66918/c1b63/migrate_error_mosaic.png",
            "srcSet": ["/static/61ec8e7b5ea6adce7959e5d258e66918/5a46d/migrate_error_mosaic.png 300w", "/static/61ec8e7b5ea6adce7959e5d258e66918/0a47e/migrate_error_mosaic.png 600w", "/static/61ec8e7b5ea6adce7959e5d258e66918/c1b63/migrate_error_mosaic.png 1200w", "/static/61ec8e7b5ea6adce7959e5d258e66918/9685e/migrate_error_mosaic.png 1336w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`内容はモザイクをつけていますが、下記のようなログが出力される場合があります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`container /~: create container: `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Docker`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` No such image: `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.`}</code></pre></div>
    <p>{`これは、一部のコンテナやイメージの移行に失敗していることを示しています。`}</p>
    <p>{`今回の移行に関してはこのエラーメッセージを migrate 時に解消しなくても問題ありませんでした。理由としては、エラー対象が自身で以前作成したデータなどが migrate できなかったのみで、後ほど再度作成すればよかったためです。`}</p>
    <h3>{`4. Docker コンテキスト情報の確認と変更`}</h3>
    <p>{`migrate が完了したら、"Docker Desktop"と"OrbStack"どちらを利用して Docker Daemon にアクセスするのか確認する必要があります。`}</p>
    <p>{`次のコマンドでコンテキストを確認します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` context `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span></code></pre></div>
    <p>{`出力例:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` context `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{`
NAME         DESCRIPTION                               DOCKER ENDPOINT                                         ERROR
default      Current DOCKER_HOST based configuration   unix:///hogehoge
orbstack *   OrbStack                                  unix:///hogehoge`}</code></pre></div>
    <p>{`"`}{`*`}{`"が今利用しているコンテキストになります`}</p>
    <p>{`"Docker Desktop" が利用されている場合は次のコマンドで OrbStack に変更します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` context use orbstack`}</code></pre></div>
    <p>{`出力例:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`% `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` context use orbstack
orbstack
Current context is now `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"orbstack"`}</span></code></pre></div>
    <h3>{`5. Docker コンテナの立ち上げ`}</h3>
    <p>{`Docker コンテナを起動して、正常に移行できたことを確認します。
完全に移行が完了したら Docker Desktop を削除しても問題ないかと思います。`}</p>
    <p>{`コンテナの起動方法は各自の利用環境に応じて手順が異なるため、本稿では詳細を割愛します。`}</p>
    <h1>{`ハマりポイントや苦労した点`}</h1>
    <p>{`少しハマったポイントや苦労した点などを記載しています。ご参考になれば幸いです。`}</p>
    <h2>{`Docker Network 周りのエラー`}</h2>
    <p>{`Docker Desktop でコンテナを立ち上げて、作業していた場合、Docker Network を多かれ少なかれ利用されているかと思います。`}</p>
    <p>{`Docker Desktop で作成していた Docker Network が存在していた場合、OrbStack で Docker Network を作成しようとすると同じ Docker Network を作成することになってしまい、ネームの被りなどが発生することがあります。
そのため、次のコマンドで Docker Network を確認します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` network `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span></code></pre></div>
    <p>{`不要なネットワークが存在していた場合、次のコマンドで削除します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` network `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` Hogehoge`}</code></pre></div>
    <h2>{`マルチビルド周りのエラー`}</h2>
    <p>{`先ほど記載した`}<a parentName="p" {...{
        "href": "#Docker-Network%E5%91%A8%E3%82%8A%E3%81%AE%E3%82%A8%E3%83%A9%E3%83%BC"
      }}>{`Docker_Network 周りのエラー`}</a>{`と似たようなエラーになります。`}</p>
    <p>{`Docker Desktop で作成済みのマルチビルド環境が OrbStack と競合する場合があります。その場合は既存のマルチビルド環境を削除し、OrbStack で新規にマルチビルド環境を構築することで問題を解消できます。`}</p>
    <p>{`エラー内容`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`error: Error response from daemon: Conflict. The container name `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"multibuild"`}</span>{` is already `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` use by container `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"container_id"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{` You have to remove `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`or `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rename`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` that container to be able to reuse that name.`}</code></pre></div>
    <p>{`次のコマンドで、新たにマルチビルドを作成して利用できます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` buildx `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` multibuild
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` buildx create --name multibuild_new_type
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` buildx use multibuild_new_type`}</code></pre></div>
    <h2>{`コンテナとローカルのファイル差分が更新されない問題`}</h2>
    <p>{`通常であれば、Volume を設定していれば、ローカルでファイルを新たに作成したり、mv, cp 等のコマンドでファイルを移したり、ファイル内を書き換えても Docker コンテナ内のファイルも更新されるかと思います。
それが今回、Docker コンテナ内でファイルが更新されない問題が起きていました。`}</p>
    <p>{`こちらの問題は、原因追及にかなり苦労しました。`}</p>
    <p>{`調査の結果、以前から利用していた docker-sync が OrbStack への移行後に`}<strong parentName="p">{`正常に`}</strong>{`動作していないことがわかりました。`}</p>
    <p>{`docker-sync とは、Docker 環境でローカルとコンテナ間のファイル同期を高速化するためのツールです。`}</p>
    <p>{`原因の切り分けには苦労しましたが、次の手順で特定できました。`}</p>
    <ul>
      <li parentName="ul">{`先行して OrbStack に移行していた別部署の方と連携を取り、両者の環境の違いを聞いた中で docker-sync の利用の有無が挙げられた`}</li>
      <li parentName="ul">{`ファイルの更新が反映されない問題であったため、ファイル同期を最適化する docker-sync が原因ではないかと推測した`}</li>
      <li parentName="ul">{`docker-sync を使用しない環境で検証して問題の解消を確認した`}</li>
    </ul>
    <p>{`そのため、OrbStack への移行を機に docker-sync の利用をやめることで コンテナとローカルのファイル差分が更新されない問題を解決しました。`}</p>
    <h1>{`移行後の OrbStack の使用感`}</h1>
    <ul>
      <li parentName="ul">{`Docker Desktop より UI などがシンプルであり、操作が容易です。`}</li>
      <li parentName="ul">{`migrates するだけで OrbStack に移行できる手軽さがよかったです。`}</li>
      <li parentName="ul">{`商用利用の料金も Docker Desktop のプランをあげるより利用料が抑えられるので、良いかと思います。`}</li>
    </ul>
    <h1>{`まとめ`}</h1>
    <p>{`OrbStack 移行を実際にやってみて、思っている以上に容易に移行できたと実感しました。`}</p>
    <p>{`もちろん Docker Desktop も良いかと思いますが、速度や利用料などを加味して、移行の選択肢として OrbStack も入ってきそうな気がしています。
また、OrbStack を実際に使ってみてください。実際に利用することでわかることが多く出てくるかと思います。`}</p>
    <h1>{`We’re hiring`}</h1>
    <p>{`メドレーでは一緒に働く仲間を大募集しています！
カジュアル面談も実施しておりますので、お話しだけでも聞いてみたい!ちょっと雑談してみたい!でも構いませんので、お気軽にお問い合わせください！`}</p>
    <p>{`募集の一覧
`}<a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>
    <p>{`医療エンジニアリング領域盛り上がっています！メドレーについてお話します！
`}<a parentName="p" {...{
        "href": "https://pitta.me/matches/BtcyDvCvUZtx"
      }}>{`https://pitta.me/matches/BtcyDvCvUZtx`}</a></p>
    <p>{`メドレーの開発チームについて知りたい方！ぜひお話ししましょう！
`}<a parentName="p" {...{
        "href": "https://pitta.me/matches/sNeEHMdSLZpB"
      }}>{`https://pitta.me/matches/sNeEHMdSLZpB`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      